<template>
  <div>
    <k-crud-page>
      <template #condition>
        <v-row no-gutters class="mx-4">
          <v-col :cols="4">
            <k-text-input
              v-model="cruddataMX.conditions.userName"
              input-width="280px"
              class="my-1"
              label="用户名"
            />
          </v-col>
          <v-col :cols="4">
            <k-text-input
              v-model="cruddataMX.conditions.workId"
              input-width="280px"
              class="my-1"
              label="用户工号"
            />
          </v-col>
          <v-col :cols="4">
            <k-tree-select
              v-model="cruddataMX.conditions.organizationId"
              tree-key="uuid"
              :has-input-search="false"
              :items="items"
              label="机构"
              class="my-2"
            />
          </v-col>
          <v-col :cols="4">
            <k-tree-select
              v-model="cruddataMX.conditions.roleId"
              input-width="280px"
              :open-on-click="true"
              class="my-1"
              label="角色"
              :items="rolesTree"
              tree-key="id"
              :has-input-search="false"
            />
          </v-col>
          <v-col :cols="4">
            <k-select
              v-model="cruddataMX.conditions.status"
              input-width="280px"
              :items="statusArr"
              item-text="label"
              item-value="id"
              class="my-1"
              label="状态"
            />
          </v-col>
          <v-col :cols="4" class="text-right">
            <k-primary-button
              class="justify-end my-3"
              text="检索"
              icon="mdi-magnify"
              @click="searchVal"
            />
          </v-col>
        </v-row>

        <!--          <k-cancel-button-->
        <!--            class="my-1 mr-4"-->
        <!--            text="重置"-->
        <!--            icon="mdi-restore"-->
        <!--            @click="crudConditionsMX().reset()"-->
        <!--          />-->
      </template>
      <template #resultlist>
        <k-crud-table
          :headers="columns"
          :items="cruddataMX.dataList"
          :total="cruddataMX.pagination.total"
          :value="cruddataMX.pagination.current"
          :sort-by="cruddataMX.ascList"
          :sort-desc="cruddataMX.descList"
          :single-select="false"
          multi-sort
          item-key="userId"
          @pageChanged="
            (page) => {
              crudPaginationMX().change(page);
            }
          "
          @sort="(options) => sortTable(options)"
        >
          <template #checkBox="{ item }">
            <v-simple-checkbox
              v-model="item.check"
              :ripple="false"
              color="primary"
              :disabled="accountState.uuid === item.userId"
              @input="changeCheck($event, item)"
            ></v-simple-checkbox>
          </template>
          <template #avatar="{ item }">
            <v-avatar size="36px">
              <img
                v-if="item.avatar"
                alt="Avatar"
                :src="commonUrl + item.avatar"
                @click="showImg(item)"
              />
              <v-icon v-else>mdi-account</v-icon>
            </v-avatar>
            <v-overlay
              v-if="item.rowPreview"
              :value="item.rowPreview"
              @click="item.rowPreview = false"
            >
              <v-img
                :lazy-src="commonUrl + item.avatar"
                :src="commonUrl + item.avatar"
              ></v-img>
            </v-overlay>
          </template>
          <template #status="{ item }">
            <div>
              <v-icon
                dense
                small
                :color="item.status === '1' ? 'success' : 'error'"
                >mdi-circle-medium</v-icon
              >
              {{ item.status === '1' ? '启用' : '禁用' }}
            </div>
          </template>
          <template #isOff="{ item }">
            <k-switch
              v-model="item.statusModel"
              :readonly="!hasPermission(['user.edit'])"
              :loading="item.loading"
              @change="onChangeStatus(item)"
            ></k-switch>
          </template>
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title>数据列表</v-toolbar-title>
              <v-spacer></v-spacer>
              <k-primary-button
                v-if="hasPermission(['user.add'])"
                icon="mdi-plus"
                text="新增用户"
                class="mr-2"
                @click="
                  crudActionMX().newItem(crud.default, onOpenEdit(crud.default))
                "
              />
              <v-btn
                v-if="hasPermission(['user.delete'])"
                :disabled="selection.length === 0"
                depressed
                class="purple white--text mr-2"
                @click="moreDel"
              >
                <v-icon left> mdi-note-multiple </v-icon>
                批量处理</v-btn
              >
              <c-upload
                v-if="hasPermission(['user.import'])"
                :api="uploadExcelCustom"
                btn-type="warning"
                btn-icon="mdi-logout-variant"
                upload-text="导入"
                accept=".xls,.xlsx"
                upload-type="btn"
                class="mr-2"
                @uploadSuccess="uploadSuccess"
              ></c-upload>
              <k-secondary-btn
                v-if="hasPermission(['user.export'])"
                text="导出"
                icon="mdi-login-variant"
                class="mr-2"
                @click="exportExl"
              />
              <k-secondary-btn
                v-if="hasPermission(['user.import'])"
                class="mr-2"
                text="下载模板"
                icon="mdi-login-variant"
                @click="exportExlTemp"
              />
              <k-crud-dialog
                v-model="crud.switch"
                :actionshow="actionShow"
                :title="crud.title"
                maxwidth="700px"
                @save="
                  () => {
                    workChange();
                    // crudActionMX().saveItem();
                  }
                "
                @close="
                  () => {
                    crudActionMX().closeEditingPage(crud);
                  }
                "
              >
                <template #dialogContent>
                  <v-form :ref="crud.form.ref" :disabled="!actionShow">
                    <table align="center">
                      <tr>
                        <td>
                          <k-text-input
                            v-model="cruddataMX.data.account"
                            label="账户"
                            class="my-2"
                            :rules="[rules.required]"
                          />
                        </td>
                        <td rowspan="3" class="pl-10">
                          <c-upload
                            v-model="cruddataMX.data.avatar"
                            :api="uploadImg"
                            upload-type="img"
                            :disabled="!actionShow"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <k-text-input
                            v-model="cruddataMX.data.userName"
                            label="名字"
                            class="my-2"
                            :rules="[rules.required]"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <k-text-input
                            v-model="cruddataMX.data.workId"
                            label="工号"
                            class="my-2"
                            :rules="[globalRules.empty]"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <k-tree-select
                            v-model="cruddataMX.data.organizationId"
                            tree-key="uuid"
                            :has-input-search="false"
                            :items="items"
                            label="机构"
                            class="my-2"
                            :rules="[globalRules.empty]"
                          />
                        </td>
                        <td>
                          <k-tree-select
                            v-model="cruddataMX.data.roleIdList"
                            :has-input-search="false"
                            :has-check-box="true"
                            class="my-1"
                            label="角色"
                            :items="rolesTree"
                            selectable
                            @click="onclickRole"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <k-text-input
                            v-model="cruddataMX.data.identityCardNumber"
                            label="身份证号"
                            :rules="[globalRules.empty,globalRules.idCard]"
                            class="my-2"
                          />
                        </td>
                        <td>
                          <k-text-input
                            v-model="cruddataMX.data.telNo"
                            :rules="[globalRules.empty,globalRules.phone]"
                            label="联系电话"
                            class="my-2"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <k-text-input
                            v-model="cruddataMX.data.title"
                            label="职务"
                            class="my-2"
                          />
                        </td>
                        <td>
                          <k-text-input
                            v-model="cruddataMX.data.address"
                            label="地址"
                            class="my-2"
                          />
                        </td>
                      </tr>
                      <tr v-if="!cruddataMX.data.userId">
                        <td>
                          <k-text-input
                            v-model="cruddataMX.data.password"
                            label="密码"
                            class="my-2"
                            :rules="[
                              rules.required,
                              globalRules.lengthTest(
                                cruddataMX.data.password,
                                6
                              ),
                              globalRules.likeTest(
                                cruddataMX.data.password,
                                'passwordConfirm',
                                [
                                  {
                                    passwordConfirm:
                                      cruddataMX.data.passwordConfirm,
                                  },
                                  {
                                    passwordConfirm:
                                      cruddataMX.data.passwordConfirm,
                                  },
                                ],
                                '请确认输入的密码是否相同！'
                              ),
                            ]"
                          />
                        </td>
                        <td>
                          <k-text-input
                            v-model="cruddataMX.data.passwordConfirm"
                            label="确认密码"
                            class="my-2"
                            :rules="[
                              rules.required,
                              globalRules.lengthTest(
                                cruddataMX.data.passwordConfirm,
                                6
                              ),
                              globalRules.likeTest(
                                cruddataMX.data.passwordConfirm,
                                'password',
                                [
                                  { password: cruddataMX.data.password },
                                  { password: cruddataMX.data.password },
                                ],
                                '请确认输入的密码是否相同！'
                              ),
                            ]"
                          />
                        </td>
                      </tr>
                    </table>
                  </v-form>
                </template>
              </k-crud-dialog>
            </v-toolbar>
          </template>
          <template #actions="{ item }">
            <k-table-primary-btn text="查看" @click="onOpenEdit(item, 2)" />
            <k-table-warning-btn
              v-if="hasPermission(['user.edit'])"
              text="编辑"
              class="mr-2"
              @click="crudActionMX().editItem(item, () => onOpenEdit(item, 1))"
            >
            </k-table-warning-btn>
            <k-table-error-btn
              v-if="hasPermission(['user.delete'])"
              text="删除"
              :disabled="accountState.uuid === item.userId"
              @click="crudActionMX().deleteItem({ userId: item.userId })"
            >
            </k-table-error-btn>
          </template>
        </k-crud-table>
      </template>
    </k-crud-page>
  </div>
</template>
<script>
import encryption from '@/utils/encryption';
import httpConfig from '@/config/http.config';
import { uploadExcelAccount, uploadImg } from '@/api/upload';
import { exportAccount, exportAccountTemp } from '@/api/exportsExcel';

export default {
  data() {
    return {
      uploadImg,
      uploadExcelCustom: uploadExcelAccount,
      actionShow: true,
      items: [],
      statusArr: [
        { id: 1, label: '启用' },
        { id: 0, label: '禁用' },
      ],
      selection: [], //表格已选
      commonUrl: httpConfig.imgUrl,
      fileDomainUrl: '',
      test: [],
      rolesTree: [],
      crud: {
        name: '用户',
        title: '', // 在Mixin中被修改
        switch: false,
        uploading: false,
        loading: false,
        form: {
          ref: 'accountModalForm',
        },
        select: {
          roles: {
            loading: false,
            current: 1,
            defaultPageSize: 10,
            data: [],
            dataset: [],
          },
        },
        default: {
          uuid: '',
          avatar: '',
          userName: '',
          workId: '',
          groupList: [],
          roleList: [],
          roleIdList: [],
          organizationId: [],
          title: '',
          address: '',
          status: '',
          account: '',
          identityCardNumber: '',
          telNo: '',
          password: '',
          passwordConfirm: '',
        },
      },
      columns: [
        { text: '', value: 'checkBox', sortable: false, check: false },
        { text: '序号', value: 'indexNum', sortable: false },
        { text: '姓名', value: 'userName', sortable: false },
        { text: '工号', value: 'workId', sortable: false },
        { text: '角色分组', value: 'groupList', sortable: false },
        { text: '用户角色', value: 'roleList', sortable: false },
        { text: '所属机构', value: 'orgName', sortable: false },
        { text: '职务', value: 'title', sortable: false },
        { text: '照片', value: 'avatar', sortable: false },
        { text: '地址', value: 'address', sortable: false },
        { text: '状态', value: 'status', sortable: false },
        { text: '注册时间', value: 'createTime', sortable: false },
        { text: '是否启用', value: 'isOff', sortable: false },
        { text: '操作', value: 'actions', sortable: false },
      ],
      rules: {
        required: (value) => !!value || '不能为空',
      },
    };
  },
  watch: {
    fileDomainUrl() {
      let newItems = [];
      for (var i = 0; i < this.cruddataMX.dataList.length; i++) {
        var data = this.cruddataMX.dataList[i];
        if (data.avatar) {
          data.avatarUrl = this.fileDomainUrl + data.avatar;
        }
        newItems.push(data);
      }
      this.cruddataMX.dataList = newItems;
    },
  },
  created() {
    this.crudInitMX(
      this.crud,
      {
        list: (params) => {
          params.organizationId = params.organizationId?.length
            ? params.organizationId.toString()
            : null;
          params.roleId = params.roleId?.length
            ? params.roleId.toString()
            : null;
          return this.$api.system.user.getUser(params);
        },
        add: (params) => {
          let ms = new Date().getTime();
          params.pstimestamp = ms;
          params.password = encryption.AES.encrypt(
            params.password,
            params.pstimestamp
          );
          params.organizationId = params.organizationId.toString();
          return this.$api.system.user.addUser(params);
        },
        edit: (params) => {
          params.password = null;
          params.organizationId = params.organizationId.toString();
          return this.$api.system.user.editUser(params);
        },
        delete: (params) => {
          // params.userId = params.userId;
          let param = {
            userId: params.userId,
          };
          // params.userId = params.userId.toString();
          return this.$api.system.user.deleteUser(param);
        },
      },
      {
        organizationId: [],
        status: null,
        roleId: [],
      },
      {
        add: [
          'avatar',
          'userName',
          'workId',
          'groupList',
          'roleList',
          'roleIdList',
          'organizationId',
          'title',
          'address',
          'status',
          'account',
          'identityCardNumber',
          'telNo',
          'password',
        ],
        edit: [
          'uuid',
          'userId',
          'avatar',
          'userName',
          'workId',
          'groupList',
          'roleList',
          'roleIdList',
          'organizationId',
          'title',
          'address',
          'status',
          'account',
          'identityCardNumber',
          'telNo',
          'password',
        ],
      },
      true,
      () => {
        this.callbackAfterInit();
      }
    );
  },
  mounted() {
    this.$api.file.getDomain().then((res) => {
      this.fileDomainUrl = res.message;
    });
    this.onclickRole();
    this.getTree();
  },
  methods: {
    async workChange() {
      // eslint-disable-next-line no-debugger
      debugger;
      if (
        this.cruddataMX.data.workId &&
        this.cruddataMX.data.organizationId.length
      ) {
        const query = {
          orgUuid: this.cruddataMX.data.organizationId[0],
          workId: this.cruddataMX.data.workId,
          userId: this.cruddataMX.data.userId || undefined,
        };
        const res = await this.$api.system.account.checkOne(query);
        if (!res.data[0]) {
          this.crudActionMX().saveItem();
        } else {
          this.$alert.warning('工号重复');
        }
      } else {
        this.crudActionMX().saveItem();
      }

      // return !res ? true : '！';
    },
    checkOne(id = undefined) {
      if (id) {
        if (this.cruddataMX.data.organizationId.length) {
          console.log(this.workChange(id));
          return this.workChange(id);
        } else {
          return '请选择机构';
        }
      } else {
        return '请输入工号';
      }
    },
    changeCheck(e, item) {
      console.log(this.accountState);
      if (e) {
        this.selection.push(item);
      } else {
        this.selection = this.selection.filter(
          (val) => val.userId !== item.userId
        );
      }
    },
    uploadSuccess() {

      this.crudConditionsMX().resetPage();
    },
    sortTable(options) {
      options.organizationId = options.organizationId?.length
        ? options.organizationId.toString()
        : null;
      options.roleId = options.roleId?.length
        ? options.roleId.toString()
        : null;
      this.crudPaginationMX().sort(options);
    },
    exportExl() {
      const obj = this.crudConditionsMX().get();
      obj.organizationId = obj.organizationId?.length
        ? obj.organizationId.toString()
        : null;
      obj.roleId = obj.roleId?.length ? obj.roleId.toString() : null;
      exportAccount(obj, '用户管理');
    },
    exportExlTemp() {
      const obj = this.crudConditionsMX().get();
      obj.organizationId = obj.organizationId?.length
        ? obj.organizationId.toString()
        : null;
      obj.roleId = obj.roleId?.length ? obj.roleId.toString() : null;
      exportAccountTemp(obj, '用户管理模板');
    },
    moreDel() {
      const userIds = this.selection.map((item) => item.userId);
      this.$api.system.account.delMore(userIds).then(({ code, message }) => {
        if (code === 200 || code === 0) {
          this.$alert.success(message);
          this.selection = [];
          this.crudConditionsMX().resetPage();
        }
      });
    },
    showImg(item) {
      this.$set(item, 'rowPreview', true);
    },
    searchVal() {
      this.crudConditionsMX().resetPage();
    },
    getTree() {
      this.$api.organization.organization.getOrTree().then(({ code, data }) => {
        if (code === 200) {
          this.items = data;
        }
      });
    },
    callbackAfterInit() {
      let newItems = [];
      for (var i = 0; i < this.cruddataMX.dataList.length; i++) {
        var data = this.cruddataMX.dataList[i];
        data.statusModel = data.status === '1' ? true : false;
        if (data.avatar) {
          data.avatarUrl = this.fileDomainUrl + data.avatar;
        }
        data.id = data.uuid;
        data.roleId = data.uuid;
        newItems.push(data);
      }
      this.cruddataMX.dataList = newItems;
    },
    onclick() {},
    async onOpenEdit(item, index) {
      /*
       * @parama index 1 编辑 2 详情
       * */
      const data = { ...item };
      if (data.organizationId.length && data.organizationId) {
        data.organizationId = [data.organizationId];
      }
      if (index) {
        this.actionShow = index === 1;
      } else {
        this.actionShow = true;
      }
      this.$set(this.cruddataMX, 'data', data);
      this.crud.switch = true;
      this.onclickRole();
    },
    onclickRole() {
      this.$api.system.role.getRoleGroup().then((res) => {
        if (res.data) {
          this.rolesTree = res.data.filter((item) => item.children);
          console.log('roletree:', this.rolesTree);
        }
      });
    },
    onChangeStatus(val) {
      const item = { ...val };
      item.status = item.statusModel === true ? '0' : '1';
      item.password = null;
      // item.loading = true;
      this.$api.system.user
        .editUser({ status: item.status, userId: item.userId })
        .then(() => {
          // for (var i = 0; i < this.cruddataMX.dataList.length; i++) {
          //   var item = this.cruddataMX.dataList[i];
          //   if (item.uuid === res.data[0].uuid) {
          //     Object.assign(item, res.data[0]);
          //     item.loading = false;
          //   }
          // }
          // this.crudConditionsMX().resetPage();
          this.crudActionMX().list();
        })
        .catch((e) => {
          // TODO 值还原
          var origin = e.origin;
          if (origin) {
            origin.loading = false;
          }
          console.log(e);
        })
        .finally(() => {
          this.$forceUpdate();
        });
    },
  },
};
</script>
